'use client'

import React from 'react'
import { ReactComponent as PinkCheck } from '@/assets/icons/pink-check.svg'
import { ReactComponent as FreeIcon } from '@/assets/icons/heart-circle-white.svg'
import { ReactComponent as TeacherProIcon } from '@/assets/icons/stars-white.svg'
import { ReactComponent as OrgIcon } from '@/assets/icons/org-white.svg'
import { ReactComponent as OrgBubbles } from '@/assets/icons/org-bubbles.svg'

import { Box, Button, CircularProgress, Typography } from '@mui/material'
import useUtils from '@/hooks/useUtilities'

interface Plan {
  name: string
  id: string
  price: string
  price_interval: string
  tagline: string
  features: string[]
  cta: string
  icon: React.ReactNode
  iconBgStyles: {
    bgcolor: string
    filter: string
  }
}

interface Product {
  id: string
  priceId: string
}
interface Prices {
  id: string
  active: boolean
  priceId: string
}
interface Products {
  id: string
  prices: Prices[]
  role: 'teacher_pro' | 'organization' | 'basic'
}

const pricingPlans = [
  {
    name: 'Free',
    id: 'basic',
    price: '$0',
    price_interval: '/ month',
    tagline: 'Best for teacher use',
    features: [
      'Up to 100 active students',
      'Use all four student actions',
      'Add media to pages',
      'AI Builder: Up to 12 messages / day',
      'Assignment results',
    ],
    cta: 'Current Plan',
    icon: <FreeIcon />,
    iconBgStyles: {
      bgcolor: 'rgba(238, 70, 188, 1)',
      filter: 'blur(15px)',
    },
  },
  {
    name: 'Teacher Pro',
    id: 'teacher_pro',
    price: '$16',
    price_interval: '/ month (billed annually)',
    tagline: 'Best for teacher use',
    features: [
      'All starter features, plus:',
      'Respond instant feedback summary',
      'Respond pass-fail auto-grading',
      'AI context',
      'Gradebook',
      'Create spaces',
    ],
    cta: 'Get started',
    icon: <TeacherProIcon />,
    iconBgStyles: {
      bgcolor: 'rgba(105, 56, 239, 1)',
      filter: 'blur(15px)',
    },
  },
  {
    name: 'Organization',
    id: 'organization',
    price: 'Custom',
    tagline: 'Best for schools and districts',
    features: [
      'All starter features, plus:',
      'AI Builder: Unlimited messages & document upload',
      'Rubric instant feedback & auto-grading',
      'WIDA & ACTFL level estimation',
      'Administrator dashboard',
      'Invoice (purchase order) billing options',
    ],
    cta: 'Contact sales',
    icon: <OrgIcon />,
    iconBgStyles: {
      bgcolor: 'rgba(54, 191, 250, 1)',
      filter: 'blur(15px)',
    },
  },
]

const featureComparison = [
  {
    category: 'Usage Limits',
    features: [
      {
        name: 'Active students',
        free: 'Up to 100',
        teacherPro: 'Up to 100',
        organization: 'Custom',
      },
      {
        name: 'Rubrics',
        free: false,
        teacherPro: false,
        organization: true,
      },
      {
        name: 'AI Builder',
        free: 'Up to 12 messages / day',
        teacherPro: 'Up to 12 messages / day',
        organization: 'Unlimited',
      },
      {
        name: 'Spaces',
        free: 'Join',
        teacherPro: 'Join & create',
        organization: 'Join & create',
      },
    ],
  },
  {
    category: 'Activity Builder: Add Student Actions',
    features: [
      {
        name: 'Multiple choice',
        free: true,
        teacherPro: true,
        organization: true,
      },
      {
        name: 'Repeat - speaking',
        free: true,
        teacherPro: true,
        organization: true,
      },
      {
        name: 'Respond - writing',
        free: true,
        teacherPro: true,
        organization: true,
      },
      {
        name: 'Respond - speaking',
        free: true,
        teacherPro: true,
        organization: true,
      },
    ],
  },
  {
    category: 'Respond: Instant Feedback & Automated Grading',
    features: [
      {
        name: 'Instant feedback',
        free: false,
        teacherPro: 'Summary only',
        organization: 'Full access',
      },
      {
        name: 'Auto-grading methods',
        free: false,
        teacherPro: 'Pass-fail',
        organization: 'Pass-fail & Rubric',
      },
      {
        name: 'AI media context',
        free: false,
        teacherPro: true,
        organization: true,
      },
      {
        name: 'Transcript with grammar insights',
        free: false,
        teacherPro: false,
        organization: true,
      },
      {
        name: 'Detailed rubric results',
        free: false,
        teacherPro: false,
        organization: true,
      },
      {
        name: 'WIDA or ACTFL level estimation',
        free: false,
        teacherPro: false,
        organization: true,
      },
      {
        name: 'Suggested response',
        free: false,
        teacherPro: false,
        organization: true,
      },
      {
        name: 'AI feedback suggestions',
        free: false,
        teacherPro: false,
        organization: true,
      },
    ],
  },
  {
    category: 'Activity Builder: Add Multi-Media',
    features: [
      {
        name: 'Create pages with media & student actions',
        free: true,
        teacherPro: true,
        organization: true,
      },
      {
        name: 'Images, video, audio, plain & rich text, embed URL, text-to-speech',
        free: true,
        teacherPro: true,
        organization: true,
      },
      {
        name: 'Integrations: Youtube video search, Google & Unsplash image search',
        free: true,
        teacherPro: true,
        organization: true,
      },
      {
        name: 'Create media-only pages',
        free: true,
        teacherPro: true,
        organization: true,
      },
      {
        name: 'Document upload, audio upload',
        free: false,
        teacherPro: false,
        organization: true,
      },
    ],
  },
  {
    category: 'Assignments',
    features: [
      {
        name: 'Practice Mode',
        free: true,
        teacherPro: true,
        organization: true,
      },
      {
        name: 'Assignment Results',
        free: true,
        teacherPro: true,
        organization: true,
      },
      {
        name: 'Gradebook',
        free: false,
        teacherPro: true,
        organization: true,
      },
      {
        name: 'Assessment Mode',
        free: false,
        teacherPro: false,
        organization: true,
      },
    ],
  },
  {
    category: 'Classrooms',
    features: [
      {
        name: 'Content Analytics',
        free: false,
        teacherPro: false,
        organization: true,
      },
      {
        name: 'Student Reports',
        free: false,
        teacherPro: false,
        organization: true,
      },
      {
        name: 'Classroom Analytics',
        free: false,
        teacherPro: false,
        organization: true,
      },
    ],
  },
  {
    category: 'Integrations',
    features: [
      {
        name: 'Single Sign on',
        free: 'Clever & Google',
        teacherPro: 'Clever & Google',
        organization: 'Clever & Google',
      },
      {
        name: 'Roster Syncing',
        free: 'Clever & Google',
        teacherPro: 'Clever & Google',
        organization: 'Clever, Google, Canvas, Schoology, Moodle, and more',
      },
      {
        name: 'Grade Passback',
        free: false,
        teacherPro: false,
        organization: true,
      },
    ],
  },
  {
    category: 'Billing, Admin & Legal',
    features: [
      {
        name: 'Purchase via Credit Card',
        free: false,
        teacherPro: true,
        organization: true,
      },
      {
        name: 'Purchase via Invoice (Purchase Order)',
        free: false,
        teacherPro: false,
        organization: true,
      },
      {
        name: 'Administrator Dashboard',
        free: false,
        teacherPro: false,
        organization: true,
      },
      {
        name: 'Master Service Agreement',
        free: false,
        teacherPro: false,
        organization: true,
      },
      {
        name: 'Data Privacy Agreement',
        free: false,
        teacherPro: false,
        organization: true,
      },
    ],
  },
  {
    category: 'Support',
    features: [
      {
        name: 'Chat support',
        free: false,
        teacherPro: true,
        organization: true,
      },
      {
        name: 'Video call & email support',
        free: false,
        teacherPro: true,
        organization: true,
      },
      {
        name: 'Live Professional Development Sessions',
        free: false,
        teacherPro: false,
        organization: true,
      },
      {
        name: 'Dedicated Success Manager',
        free: false,
        teacherPro: false,
        organization: true,
      },
    ],
  },
]

export function PricingPageComponent({
  currentPlan,
  products,
  loading,
  handleStartPlan,
  isModal,
  highlightedPlan,
}: {
  currentPlan: string
  products: any
  loading: boolean
  handleStartPlan: (plan: any) => void
  isModal: boolean
  highlightedPlan?: 'teacher_pro' | 'organization'
}) {
  const { isMobile } = useUtils()
  return (
    <div className="container mx-auto px-4 py-16 max-w-6xl">
      <div className="text-center mb-16">
        <Typography fontSize={18.4} fontWeight={400} color="rgba(221, 37, 144, 1)">
          Pricing
        </Typography>
        <Typography fontSize={60} fontWeight={500}>
          Streamline grading & feedback.{' '}
          <span
            style={{
              WebkitTextFillColor: 'transparent',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              backgroundImage: 'linear-gradient(135deg, #F093FB 0%, #F5576C 100%)',
            }}>
            Start free.
          </span>
        </Typography>
        <Typography fontSize={24} fontWeight={400}>
          Choose the perfect plan for your needs
        </Typography>
      </div>

      <div className="grid md:grid-cols-3 gap-8 mb-16">
        {pricingPlans.map((plan, index) => (
          <div
            key={plan.id}
            className="p-6 rounded-lg flex flex-col gap-4"
            style={{
              border:
                highlightedPlan === plan.id
                  ? plan.id === 'teacher_pro'
                    ? '2px solid #9B8AFB'
                    : plan.id === 'organization'
                      ? '2px solid #36BFFA'
                      : 'none'
                  : 'none',
              background:
                highlightedPlan === plan.id
                  ? plan.id === 'teacher_pro'
                    ? 'rgba(105, 56, 239, 0.1)'
                    : plan.id === 'organization'
                      ? 'rgba(54, 191, 250, 0.1)'
                      : 'transparent'
                  : 'transparent',
            }}>
            <PlanLabel plan={plan} currentPlan={currentPlan} />
            <Typography fontSize={16} fontWeight={600} color="#475467">
              {plan.tagline}
            </Typography>
            <div className="mb-6">
              <Typography sx={{ display: 'flex', alignItems: 'center', gap: '3px' }} fontSize={30} fontWeight={600}>
                {plan.price} <span style={{ fontSize: 12, fontWeight: 400 }}>{plan.price_interval}</span>
              </Typography>
            </div>
            <ul className="mb-6 flex-grow">
              {plan.features.map((feature, index) => (
                <li key={index} className="flex items-start mb-4 gap-2">
                  <span>
                    <PinkCheck />
                  </span>
                  <span>
                    <Typography fontSize={14} fontWeight={400}>
                      {feature}
                    </Typography>
                  </span>
                </li>
              ))}
            </ul>
            {currentPlan === plan.id || currentPlan === 'organization' ? (
              <Box sx={{ height: '36.5px' }} />
            ) : (
              <>
                <PlanButton
                  products={products}
                  plan={plan}
                  currentPlan={currentPlan}
                  loading={loading}
                  handleStartPlan={handleStartPlan}
                />
              </>
            )}
          </div>
        ))}
      </div>

      <div
        style={{
          display: isMobile ? 'none' : 'block',
        }}>
        <Box sx={{ mb: '20px' }}>
          <Typography gutterBottom fontSize={48} fontWeight={600}>
            Compare plans
          </Typography>
          <Typography fontSize={24} fontWeight={400}>
            Choose the perfect plan for your needs
          </Typography>
        </Box>
        <div>
          <table className="w-full">
            <thead>
              <tr
                style={{
                  position: 'sticky',
                  top: isModal ? -20 : 80,
                  zIndex: 10,

                  height: '100%',
                }}>
                <th
                  style={{
                    borderRadius: '16px 0 0 16px',
                    backgroundColor: '#F2F4F7',
                  }}
                  className="text-left py-4 px-4 font-normal text-gray-500"></th>
                {pricingPlans.map((plan, index) => (
                  <th
                    key={plan.id}
                    style={{
                      width: 250,
                      backgroundColor: '#F2F4F7',
                      padding: '2px',
                      // borderRadius: '16px',
                      borderRadius: index === pricingPlans.length - 1 ? '0 16px 16px 0' : '0',
                    }}>
                    <Box
                      sx={{
                        backgroundColor:
                          highlightedPlan === plan.id
                            ? plan.id === 'teacher_pro'
                              ? 'rgba(105, 56, 239, 0.1)'
                              : plan.id === 'organization'
                                ? 'rgba(54, 191, 250, 0.1)'
                                : '#EAECF0'
                            : '#EAECF0',
                        width: '100%',
                        p: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',

                        // backdropFilter: 'blur(10px)',
                        border:
                          highlightedPlan === plan.id
                            ? plan.id === 'teacher_pro'
                              ? '2px solid #9B8AFB'
                              : plan.id === 'organization'
                                ? '2px solid #36BFFA'
                                : 'none'
                            : 'none',
                        borderRadius: '16px',
                      }}>
                      <PlanLabel plan={plan} currentPlan={currentPlan} table />
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '3px',
                        }}>
                        <span
                          style={{
                            fontSize: '30px',
                            fontWeight: 600,
                            color: '#344054',
                          }}>
                          {plan.price}
                        </span>{' '}
                        <span
                          style={{
                            fontSize: '12px',
                            fontWeight: 400,
                            color: '#344054',
                          }}>
                          {plan.price_interval}
                        </span>
                      </Box>
                      <Box>
                        {currentPlan === plan.id || currentPlan === 'organization' ? (
                          <Box sx={{ height: '36.5px' }} />
                        ) : (
                          <>
                            <PlanButton
                              plan={plan}
                              currentPlan={currentPlan}
                              loading={loading}
                              handleStartPlan={handleStartPlan}
                              products={products}
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {featureComparison.map((category, categoryIndex: number) => (
                <React.Fragment key={categoryIndex}>
                  <tr>
                    <td
                      colSpan={4}
                      className="py-4 px-4 font-semibold"
                      style={{
                        background: '#F2F4F7',
                        padding: '8px',
                        fontSize: '20px',
                        fontWeight: 600,
                        borderRadius: '16px',
                      }}>
                      {category.category}
                    </td>
                  </tr>
                  {category.features.map((feature, featureIndex) => (
                    <tr key={`${categoryIndex}-${featureIndex}`} className={featureIndex === 0 ? '' : 'border-t'}>
                      <td
                        className="py-4 px-4"
                        style={{
                          color: '#344054',
                          fontSize: '18px',
                          fontWeight: 500,
                        }}>
                        {feature.name}
                      </td>
                      <td className="text-center py-4 px-4">{renderFeatureValue(feature.free)}</td>
                      <td
                        className="text-center py-4 px-4"
                        style={{
                          backgroundColor:
                            highlightedPlan === 'teacher_pro' ? 'rgba(105, 56, 239, 0.1)' : 'transparent',
                        }}>
                        {renderFeatureValue(feature.teacherPro)}
                      </td>
                      <td
                        className="text-center py-4 px-4"
                        style={{
                          backgroundColor:
                            highlightedPlan === 'organization' ? 'rgba(54, 191, 250, 0.1)' : 'transparent',
                        }}>
                        {renderFeatureValue(feature.organization)}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

function renderFeatureValue(value: boolean | string | undefined) {
  if (typeof value === 'boolean') {
    return value ? (
      <span className="flex justify-center">
        <PinkCheck />{' '}
      </span>
    ) : null
  }
  return (
    <span
      style={{
        fontSize: '16px',
        fontWeight: 400,
        color: 'rgba(52, 64, 84, 1)',
      }}>
      {value || '-'}
    </span>
  )
}

const PlanLabel = ({ plan, currentPlan, table }: { plan: Plan; currentPlan: string; table?: boolean }) => {
  return (
    <Box
      sx={{
        bgcolor: '#F2F4F7',
        borderRadius: '16px',
        p: '8px',
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        position: 'relative',
      }}>
      {currentPlan && plan.id === currentPlan && (
        <Box sx={{ position: 'absolute', top: table ? -10 : -30, right: 0 }}>
          <CurrentPlanChip plan={currentPlan} />
        </Box>
      )}
      <Box
        sx={{
          bgcolor: plan.iconBgStyles.bgcolor,
          borderRadius: '50%',
          filter: plan.iconBgStyles.filter,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '40px', // or appropriate size for your icon
          height: '40px', // same here, adjust based on
        }}></Box>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: '12px',
        }}>
        <span>{plan.icon}</span>
        {plan.name === 'Organization' && (
          <span
            style={{
              position: 'absolute',
              top: '50%',
              left: '-24px',
              transform: 'translateY(-50%)',
            }}>
            <OrgBubbles />
          </span>
        )}
      </Box>

      <Typography fontSize={24} fontWeight={600}>
        {plan.name}
      </Typography>
    </Box>
  )
}
const colors = {
  basic: {
    color: 'rgba(246, 112, 199, 1)',
    borderColor: 'rgba(238, 70, 188, 1)',
  },
  teacher_pro: {
    color: 'rgba(155, 138, 251, 1)',
    borderColor: 'rgba(122, 90, 248, 1)',
  },
  organization: {
    color: 'rgba(54, 191, 250, 1)',
    borderColor: 'rgba(54, 191, 250, 1)',
  },
}
const CurrentPlanChip = ({ plan }: { plan: string }) => {
  return (
    <Box
      sx={{
        borderRadius: '16px',
        p: '2px',
        width: 'fit-content',
        border: '1.5px solid',
        borderColor: colors[plan].borderColor,
        color: colors[plan].color,
        fontSize: '12px',
        fontWeight: 500,
        bgcolor: 'white',
      }}>
      Current Plan
    </Box>
  )
}

const PlanButton = ({
  plan,
  currentPlan,
  loading,
  handleStartPlan,
  products,
}: {
  plan: Plan
  currentPlan: string
  loading: boolean
  handleStartPlan: (plan: any) => void
  products: Products[]
}) => {
  return (
    <Button
      fullWidth
      color="primary"
      variant={plan.id === 'organization' ? 'outlined' : plan.id === 'teacher_pro' ? 'contained' : 'text'}
      startIcon={loading && plan.id !== 'organization' && <CircularProgress color="inherit" size={20} />}
      sx={{
        bgcolor: plan.id === 'organization' ? 'white' : '',
      }}
      // className="w-full py-2 px-4 rounded-md text-sm font-medium bg-pink-500 text-white hover:bg-pink-600"
      onClick={() => {
        if (plan.id === 'organization') {
          handleStartPlan({
            id: plan.id,
          })
          return
        } else if (plan.id === 'teacher_pro') {
          const stripeProduct = products.find(product => product.role === 'teacher_pro')
          const price = stripeProduct?.prices.find(p => p.active)
          handleStartPlan({
            id: plan.id,
            priceId: price.id,
          })
        } else if (currentPlan === 'teacher_pro' && plan.id !== 'organization') {
          handleStartPlan('downgrade')
        }
      }}>
      {currentPlan === 'basic' || plan.id === 'organization' ? plan.cta : 'Downgrade'}
    </Button>
  )
}
